import React, { useState, useEffect } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import type { ChallengesPageType } from "../../../types/ChallengesPageType"

import {
  htmlSerializerUpdateStyle,
  linkResolver,
  Spinner,
} from "@lesmills/gatsby-theme-common"

import { NextArrow, PrevArrow } from "./SliderItems/Arrow"
import { AppendDots, Pagination } from "./SliderItems/Pagination"

// Utils
import { getActiveChallenge } from "../../../utils/challenges"
import loadable from "@loadable/component"

const ChallengeItem = loadable(() => import("../ChallengeItem"), {
  fallback: <Spinner />,
})

const RichText = loadable(() => import("prismic-reactjs"), {
  resolveComponent: components => components.RichText,
  fallback: <Spinner />,
})

const MediaModal = loadable(() => import("@lesmills/gatsby-theme-common"), {
  resolveComponent: components => components.MediaModal,
  fallback: <Spinner />,
})

// Modals
const StopChallengeModal = loadable(
  () => import("../../Modal/StopChallengeModal"),
  {
    fallback: <Spinner />,
  }
)
const AcceptChallengeModal = loadable(
  () => import("../../Modal/AcceptChallengeModal"),
  {
    fallback: <Spinner />,
  }
)
const SwitchChallengeModal = loadable(
  () => import("../../Modal/SwitchChallengeModal"),
  {
    fallback: <Spinner />,
  }
)

type Props = {|
  data: ChallengesPageType,
  user: {
    email: string,
    challengeEngagements?: Object,
    lmodSubscription?: Object,
  },
|}

const ChallengesList = ({ data, user = {} }: Props) => {
  const [acceptingChallenge, setAcceptingChallenge] = useState(null)
  const [stoppingChallenge, setStoppingChallenge] = useState(null)
  const [switchingChallenge, setSwitchingChallenge] = useState(null)
  const [isActiveSubscription, setIsActiveSubscription] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [playingVideo, setPlayingVideo] = useState("")
  const [loading, setLoading] = useState(true)

  const callbackAfterCheckingAccount = isActive => {
    setIsActiveSubscription(isActive)
    setLoading(false)
  }

  useEffect(() => {
    callbackAfterCheckingAccount(user?.lmodEntitlement)
  }, [user])

  const {
    body = [],
    challenges_swipe_guide = {},
    get_challenge_button_label,
    view_workouts_button_label,
    challenge_word,
    challenge_active_label,
    inactive_subscription_notification = {},
    download_pack_button_label,
    sign_up_button_label,

    // Accept challenge stuffs
    accept_challenge_success_message,
    accept_challenge_success_title,
    accept_challenge_title,
    subscribe_challenge_label,
    subscribe_challenge_note,
    unsubscribe_challenge_note,
    start_date_label,
    start_date_placeholder,
    close_button_label,
    cancel_calendar_button_label,
    apply_calendar_button_label,
    lmod_challenges_active_challenge_exist,
    lmod_challenges_accept_challenge_unhandled_error,

    // Stop challenge stuffs
    stop_challenge_label,
    stop_challenge_message,
    stop_challenge_success_message,
    stop_challenge_success_title,
    stop_challenge_title,
    cancel_button_label,
    lmod_challenges_no_active_challenge,
    lmod_challenges_leave_challenge_unhandled_error,

    // Switch challenge
    switch_challenge_title,
    switch_challenge_message,
    switch_challenge_button_label,
    lmod_challenges_switch_challenge_unhandled_error,
    lmod_challenges_no_existing_challenge,
  } = data || {}

  const { items = [], primary = {} } = body[1] || {}
  const challenges = items
  const {
    areChallengeEmailsSubscribed,
    activeChallengeEngagement = {
      challengeId: "",
      startDate: "",
    },
  } = user.challengeEngagements || {}
  const [activeChallenge, setActiveChallenge] = useState(
    activeChallengeEngagement && activeChallengeEngagement.challengeId
      ? {
          ...getActiveChallenge(
            activeChallengeEngagement.challengeId,
            challenges
          ),
          startDate: activeChallengeEngagement.startDate,
        }
      : null
  )

  const CustomPagination = index => (
    <div className="custom-pagination">
      <Pagination index={index} current={currentSlide} />
    </div>
  )

  const callbackAfterSavingChallenge = (id, startDate) => {
    setActiveChallenge(
      id
        ? {
            ...getActiveChallenge(id, challenges),
            startDate,
          }
        : null
    )
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "w-full pt-60",
    appendDots: AppendDots,
    customPaging: CustomPagination,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dotsClass: "slick-dots challenge-list-slick-dots",
    beforeChange: (current, next) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <section className="mx-auto mb-90 md:mb-70 max-w-screen-sm lg:max-w-challenge-list-lg">
      <h4 className="text-6xl md:text-9xl mt-54 md:mt-74 mb-25 md:mb-32 leading-32 uppercase font-primary text-gray-800 text-center">
        {primary.challenges_list_title && primary.challenges_list_title.text}
      </h4>

      <p className="challenge-swipe-guide mt-40 font-base-light text-gray-800 text-base mb-28 mx-auto w-50/100 text-center">
        {challenges_swipe_guide.text}
      </p>

      <Slider {...settings}>
        {challenges.map(challenge => {
          const challengeId = challenge.challenge_id.text
          return (
            <ChallengeItem
              key={challengeId}
              challenge={challenge}
              data={{
                get_challenge_button_label,
                view_workouts_button_label,
                challenge_word,
                challenge_active_label,
                stop_challenge_label,
                download_pack_button_label,
              }}
              setAcceptingChallenge={setAcceptingChallenge}
              setStoppingChallenge={setStoppingChallenge}
              setSwitchingChallenge={setSwitchingChallenge}
              setPlayingVideo={setPlayingVideo}
              active={activeChallenge && activeChallenge.id === challengeId}
              activeChallenge={activeChallenge}
              isActiveSubscription={isActiveSubscription}
              loading={loading}
            />
          )
        })}
      </Slider>
      {!isActiveSubscription && !loading && (
        <RichText
          render={inactive_subscription_notification.raw}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializerUpdateStyle(
            "",
            "inactive-subscription-label font-base-light text-gray-500 text-2xl leading-24 mt-10 md:mt-40 px-25 text-center"
          )}
        />
      )}
      {acceptingChallenge && (
        <AcceptChallengeModal
          challenge={acceptingChallenge}
          data={{
            accept_challenge_success_message,
            accept_challenge_success_title,
            accept_challenge_title,
            sign_up_button_label,
            subscribe_challenge_label,
            subscribe_challenge_note,
            unsubscribe_challenge_note,
            start_date_label,
            start_date_placeholder,
            close_button_label,
            cancel_calendar_button_label,
            apply_calendar_button_label,

            switch_challenge_title,
            switch_challenge_message,
            switch_challenge_button_label,
            cancel_button_label,

            // Error
            lmod_challenges_active_challenge_exist,
            lmod_challenges_accept_challenge_unhandled_error,
            lmod_challenges_switch_challenge_unhandled_error,
            lmod_challenges_no_existing_challenge,
          }}
          handleDismiss={() => setAcceptingChallenge()}
          email={user.email}
          areChallengeEmailsSubscribed={areChallengeEmailsSubscribed}
          activeChallenge={activeChallenge}
          callback={callbackAfterSavingChallenge}
        />
      )}
      {switchingChallenge && (
        <SwitchChallengeModal
          challenge={switchingChallenge}
          activeChallenge={activeChallenge}
          data={{
            switch_challenge_title,
            switch_challenge_message,
            switch_challenge_button_label,
            cancel_button_label,
          }}
          handleDismiss={isSwitch => {
            if (isSwitch) {
              setAcceptingChallenge(switchingChallenge)
            }
            setSwitchingChallenge()
          }}
        />
      )}
      {stoppingChallenge && (
        <StopChallengeModal
          challenge={stoppingChallenge}
          data={{
            stop_challenge_success_message,
            stop_challenge_success_title,
            stop_challenge_title,
            stop_challenge_message,
            cancel_button_label,
            stop_challenge_label,
            close_button_label,

            // Error
            lmod_challenges_no_active_challenge,
            lmod_challenges_leave_challenge_unhandled_error,
          }}
          handleDismiss={() => setStoppingChallenge()}
          callback={callbackAfterSavingChallenge}
        />
      )}
      {playingVideo && (
        <MediaModal
          handleDismiss={() => setPlayingVideo("")}
          video={
            playingVideo +
            "?autoplay=0&cc_load_policy=1&controls=1&fs=1&iv_load_policy=1&showinfo=1&wmode=opaque&theme=dark"
          }
        />
      )}
    </section>
  )
}

export default ChallengesList
