import React from "react"

type PaginationProps = {|
  index: number,
  current: number,
|}

export const AppendDots = dots => (
  <ul style={{ margin: "20px auto" }}> {dots} </ul>
)

export const Pagination = ({ index, current }: PaginationProps) => (
  <div
    className={`challenge-slick-dot ${index === current ? "active" : ""}`}
  ></div>
)
