import React from "react"
import { RichText } from "prismic-reactjs"
import type { ChallengesPageType } from "../../../types/ChallengesPageType"
import {
  htmlSerializerUpdateStyle,
  linkResolver,
} from "@lesmills/gatsby-theme-common"
type Props = {|
  data: ChallengesPageType,
|}
const ChallengeIntro = ({ data }: Props) => {
  const { intro_description = {}, intro_title = {} } = data || {}
  return (
    <section className="w-full my-54 md:my-70">
      <div className="mx-auto text-center text-gray-800 px-25 lg:px-100">
        <RichText
          render={intro_title.raw}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializerUpdateStyle(
            "",
            "uppercase font-primary mb-30 text-6xl leading-32 md:text-9xl"
          )}
        />
        <RichText
          render={intro_description.raw}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializerUpdateStyle(
            "",
            "font-base-light text-base leading-22 md:text-2lg md:leading-24 md:px-55"
          )}
        />
      </div>
    </section>
  )
}
export default ChallengeIntro
