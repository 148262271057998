import React from "react"
import { RichText } from "prismic-reactjs"
import type { ChallengesPageType } from "../../../types/ChallengesPageType"

import {
  htmlSerializerUpdateStyle,
  linkResolver,
} from "@lesmills/gatsby-theme-common"

type Props = {|
  data: ChallengesPageType,
|}

const ChallengeIncludes = ({ data }: Props) => {
  const { body = [] } = data || {}
  const { items = [], primary = {} } = body[0] || {}
  const challengeIncludes = items
  return (
    <section className="bg-gray-800 w-full text-center px-16 pt-55 pb-40 md:pt-70 md:pb-32 lg:px-50 xl:pb-40">
      <div className="mx-auto challenge-includes-content">
        <RichText
          render={
            primary.challenges_include_title &&
            primary.challenges_include_title.raw
          }
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializerUpdateStyle(
            "",
            "text-white uppercase mb-58 font-primary mb-31 text-6xl leading-32 md:text-9xl xl:mb-74"
          )}
        />
        <div className="w-full flex flex-wrap items-center justify-between lg:justify-around">
          {challengeIncludes.map(factor => {
            const {
              challenge_factor_icon = {
                url: "",
                alt: "",
              },
              challenges_factor_name = [],
            } = factor || {}
            return (
              <div
                className="block w-50/100 mb-60 md:w-33/100 md:mb-74 xl:w-auto"
                key={challenges_factor_name.text}
              >
                <div className="flex items-center w-challenge-include-img-wrap h-challenge-include-img-wrap mx-auto">
                  <img
                    src={challenge_factor_icon.url}
                    alt={challenge_factor_icon.alt}
                    className={`inline-block object-contain mb-18 w-challenge-include-img h-challenge-include-img ${
                      challenge_factor_icon.alt.indexOf("Testing") > -1 ||
                      challenge_factor_icon.alt.indexOf("Community") > -1
                        ? "object-cover"
                        : ""
                    }`}
                  />
                </div>
                <RichText
                  render={challenges_factor_name.raw}
                  linkResolver={linkResolver}
                  htmlSerializer={htmlSerializerUpdateStyle(
                    "",
                    "text-white font-primary text-2lg leading-24 uppercase lg:leading-22 lg:text-base w-full mx-auto"
                  )}
                />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
export default ChallengeIncludes
