import React from "react"
import { Icon } from "@lesmills/gatsby-theme-common"

type SlideArrowProps = {|
  className: string,
  style: object,
  onClick: () => void,
|}

export const NextArrow = ({ className, style, onClick }: SlideArrowProps) => (
  <div className={`${className} challenge-list-slick-next block`} style={style}>
    <Icon
      icon="slider-next"
      type="base"
      classNames="slider-arrow-icon-base"
      handleOnClick={onClick}
    />
  </div>
)

export const PrevArrow = ({ className, style, onClick }: SlideArrowProps) => (
  <div className={`${className} challenge-list-slick-prev block`} style={style}>
    <Icon
      icon="slider-prev"
      type="base"
      classNames="slider-arrow-icon-base"
      handleOnClick={onClick}
    />
  </div>
)
