import React from "react"
import isEqual from "lodash/isEqual"
import { RichText } from "prismic-reactjs"
import type { ChallengesPageType } from "../../../types/ChallengesPageType"

import {
  htmlSerializerUpdateStyle,
  linkResolver,
} from "@lesmills/gatsby-theme-common"
type Props = {|
  data: ChallengesPageType,
|}

const Hero = ({ data }: Props) => {
  const {
    hero_highlight_title = {},
    hero_title = {},
    available_on_label = {},
    hero_image = {
      alt: "",
      url: "",
      thumbnails: {
        Mobile: {},
        Tablet: {},
      },
    },
    hero_video = {},
    scroll_down_label = {},
  } = data || {}
  const heroImagePhotoThumbnails = hero_image.thumbnails || {}

  const tabletHeroImage = heroImagePhotoThumbnails.Tablet || {}
  const mobileHeroImage = heroImagePhotoThumbnails.Mobile || {}
  const heroVideoUrl = hero_video && hero_video.raw && hero_video.raw.url
  return (
    <section className="relative h-challenge-hero-sm md:h-challenge-hero-md lg:h-challenge-hero-lg w-full">
      <div className="challenge-hero-content flex justify-center flex-col h-challenge-hero-sm leading-57 absolute mx-auto left-0 right-0 text-center md:h-challenge-hero-md md:flex-row lg:h-challenge-hero-lg">
        <h2 className="font-manuka text-challenge-page-title-sm uppercase md:text-challenge-page-title-md md:flex md:flex-row md:items-center lg:text-challenge-page-title-lg">
          <RichText
            render={hero_highlight_title.raw}
            linkResolver={linkResolver}
            htmlSerializer={htmlSerializerUpdateStyle(
              "",
              "w-challenge-hero-title h-challenge-hero-title-sm text-black bg-red-800 mx-auto leading-52 py-2 md:px-10 md:py-0 md:w-auto md:mr-8 md:h-challenge-hero-title-md md:leading-102 lg:h-challenge-hero-title-lg lg:mr-16 lg:px-16 lg:leading-163"
            )}
          />
          <RichText
            render={hero_title.raw}
            linkResolver={linkResolver}
            htmlSerializer={htmlSerializerUpdateStyle("", "text-white")}
          />
        </h2>
        <RichText
          render={scroll_down_label.raw}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializerUpdateStyle(
            "",
            "text-white font-base-light text-base my-4"
          )}
        />
        <RichText
          render={available_on_label.raw}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializerUpdateStyle(
            "",
            "text-white font-base-light text-xs mb-10"
          )}
        />
      </div>
      {heroVideoUrl ? (
        <video
          autoPlay="autoPlay"
          loop="loop"
          muted="muted"
          playsInline="playsInline"
          className="absolute top-0 left-0 object-cover h-challenge-hero-sm md:h-challenge-hero-md lg:h-challenge-hero-lg lg:w-full w-full z-0"
        >
          <source src={heroVideoUrl} type="video/mp4"></source>
        </video>
      ) : (
        <picture>
          <source srcSet={hero_image.url} media="(min-width: 769px)" />
          <source srcSet={tabletHeroImage.url} media="(max-width: 768px)" />
          <img
            alt={mobileHeroImage.alt}
            src={mobileHeroImage.url}
            className="object-cover h-challenge-hero-sm md:h-challenge-hero-md lg:h-challenge-hero-lg lg:w-full absolute top-0 left-0 w-full z-0"
            data-cy="hero-img"
            data-testid="hero-img"
          />
        </picture>
      )}
    </section>
  )
}
export default React.memo(Hero, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
