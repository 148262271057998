import React from "react"
import isEqual from "lodash/isEqual"

import type { ChallengesPageType } from "../../types/ChallengesPageType"
import type { UserType } from "@lesmills/gatsby-theme-common"

import Hero from "../Hero"
import ChallengeIntro from "./ChallengeIntro"
import ChallengesList from "./ChallengesList"
import ChallengeIncludes from "./ChallengeIncludes"

type Props = {|
  data: ChallengesPageType,
  location: Object,
  lang: string,
  user: UserType,
|}

const Challenges = ({ data, user }: Props) => {
  return (
    <>
      <Hero data={data} />
      <ChallengeIntro data={data} />
      <ChallengeIncludes data={data} />
      <ChallengesList data={data} user={user} />
    </>
  )
}

export default React.memo(Challenges, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.data, nextProps.data) &&
    isEqual(prevProps.user, nextProps.user)
  )
})
